<template>
  <div>
    <v-app-bar app clipped-left :color="color" dense dark>
      <v-app-bar-nav-icon to="/"><v-img :src="logoIconUrl" width="10px" alt="wwcalc"/></v-app-bar-nav-icon>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="map" @click="updateLayersMap()"><v-icon>mdi-map</v-icon>{{ selectedLanguage['detail_component_text1'] }}</v-tab>
        <v-spacer/>
        <v-tab key="comparative"><v-icon>mdi-compare</v-icon>{{ selectedLanguage['detail_component_text2'] }}</v-tab>
        <v-spacer/>
        <v-tab key="detail" @click="startSetInterval()"><v-icon>mdi-file-chart</v-icon>{{ selectedLanguage['detail_component_text3'] }}</v-tab>
        <v-spacer/>
        <!-- LAYER MENU -->
        <v-menu offset-y v-if="tab===0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text dark v-bind="attrs" v-on="on"><v-icon>mdi-layers-triple-outline</v-icon>{{ selectedLanguage['detail_component_text4'] }}</v-btn>
          </template>
          <v-list dense>
            <v-subheader>{{ selectedLanguage['detail_component_text5'] }}</v-subheader>
              <v-list-item-group v-model="baseLayerIdx" color="primary">
                <v-list-item v-for="(item, i) in baseLayers" :key="i">
                  <v-list-item-icon>
                    <v-icon>mdi-checkerboard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"/>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
        </v-menu>
        <v-spacer/>
        <v-btn text @click="redirectFunc()">₡ {{this.balanceFormatted}}</v-btn>
        <NotificationMenu/>
      </v-tabs>
    </v-app-bar>
    <v-card class="elevation-0">
      <v-tabs-items v-model="tab">
        <v-tab-item key="map">
          <v-alert v-if="downloadingLayers" border="top" dense color="info" dark style="position: fixed; z-index: 9; width: 100%;">
            <v-progress-circular indeterminate :color="color"></v-progress-circular>
            {{ selectedLanguage['detail_component_text6'] }}
          </v-alert>
          <Map ref="mapComparative" :srid="srid" :layers="alternativesDetail" :initZoom="true" :baseLayerIdx="baseLayerIdx"></Map>
        </v-tab-item>
        <v-tab-item key="comparative">
          <v-card-text>
            <v-alert v-if="downloadingComparative" border="top" dense color="info" dark>
              <v-progress-circular indeterminate :color="color"></v-progress-circular>
              {{ selectedLanguage['detail_component_text7'] }}
            </v-alert>
            <v-alert v-else-if="renderingComparative" border="top" dense color="info" dark>
              <v-progress-circular indeterminate :color="color"></v-progress-circular>
              {{ selectedLanguage['detail_component_text8'] }}
            </v-alert>
            <div v-if="compareAlternativesPdf">
              <VuePdfEmbed :source="compareAlternativesPdf" v-if="$vuetify.breakpoint.smAndDown" class="ml-0 mt-0 mb-0 mr-0" @rendered="handleDocumentRender"/>
              <VuePdfEmbed :source="compareAlternativesPdf" v-else @rendered="handleDocumentRender"/>
            </div>
          </v-card-text>
        </v-tab-item>
        <v-tab-item key="detail">
          <v-card-text v-if="alternatives != null ">
            <v-data-table :headers="alternativesHeaders" :hide-default-footer="true" disable-pagination :items="Object.values(alternatives)" class="elevation-0" >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{ selectedLanguage['detail_component_text9'] }}</v-toolbar-title>
                  <v-spacer/>
                </v-toolbar>
              </template>
              <template v-slot:item.priceFormatted="{ item }">
                <div text v-if="item.priceFormatted == 0">
                </div>
                <div v-else>{{ item.priceFormatted }}</div>
              </template>
              <template v-slot:item.lengthFormatted="{ item }">
                <div text v-if="item.priceFormatted == 0">
                </div>
                <div v-else>{{ item.lengthFormatted }}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div v-if="!item.status">
                  <v-row v-if="item.priceFormatted == 0" style="cursor: unset;" :ripple="false">
                    <v-spacer/>
                    <div style="display: flex; flex-direction: column; width: 80%;">
                      <v-progress-linear style="pointer-events: none" v-model.number="item.status" color="light-blue" height="25" striped rounded>
                        <template>
                          <strong>{{ Math.ceil(item.status) }}%</strong>
                        </template>
                      </v-progress-linear>
                    </div>
                    <v-spacer/>
                  </v-row>
                  <v-btn class="noHover" text v-else-if="!loadingAlternativeReport[item.number-1]" style="cursor: unset;" :ripple="false">
                    <v-icon color="grey">
                    </v-icon>
                  </v-btn>
                  <img v-else src="/img/characterize.gif" alt="Loading" style="max-height: 10vh; max-width: 50px;"/>
                </div>
                <div v-else>

                  <v-btn class="noHover" text v-if="item.status.startsWith('Done')" style="cursor: unset;" :ripple="false"> 
                    <v-icon color="blue"></v-icon>
                  </v-btn>
                  <div v-else-if="item.status.startsWith('Send')" style="cursor: unset;" :ripple="false">
                  <v-btn class="noHover" text>
                    <img src="/img/cloud.gif" alt="Loading" style="max-height: 10vh; max-width: 50px;"/>
                  </v-btn>
                  </div>
                  <v-icon v-else-if="item.status.startsWith('Error')" color="red" style="cursor: unset;" :ripple="false">mdi-alert-circle-outline</v-icon>
                  <v-row v-else-if="!isNaN(parseFloat(item.status)) && parseFloat(item.status) >= 0 || item.priceFormatted == 0" style="cursor: unset;">
                    <v-spacer/>
                    <div style="display: flex; flex-direction: column; width: 80%;" :ripple="false">
                      <v-progress-linear style="pointer-events: none" v-model.number="item.status" color="light-blue" height="25" striped rounded v-if="item.status != null">
                        <template>
                          <strong>{{ Math.ceil(item.status) }}%</strong>
                        </template>
                      </v-progress-linear>
                    </div>
                    <v-spacer/>
                  </v-row>
                  <img v-else src="/img/characterize.gif" alt="Loading" style="max-height: 10vh; max-width: 50px; cursor: unset;"/>
                </div>
              </template>
              <template v-slot:item.optimization="{ item }">
                <!-- <div>{{ item }}</div> -->
                <v-btn v-if="item.priceFormatted == 0" text @click="handleClick(item)" disabled style="opacity: 0;">
                  <v-icon color="grey">
                    mdi-file-document-edit-outline
                  </v-icon>
                </v-btn>
                <!-- aqui -->
                <div v-else-if="item.priceFormatted != 0">
                  <v-tooltip top v-if=" !item.status && !loadingAlternativeReport[item.number-1]">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text @click="handleClick(item)" v-bind="attrs" v-on="on">
                        <v-icon color="grey">
                          mdi-file-document-edit-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ selectedLanguage['tooltip_detailed_btn_text_1'] }}</span>
                  </v-tooltip>
                  <v-tooltip top v-else-if="item.status.startsWith('Done')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text v-bind="attrs" v-on="on" :href="currentCalc.cloud_link+'?path=%2F'+selectedLanguage['link_cloud_text_1']+'%20'+item.number" target="_blank" style="text-decoration: none;"> 
                        <v-icon color="blue">mdi-cloud-arrow-right-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ selectedLanguage['tooltip_detailed_btn_text_3'] }}</span>
                  </v-tooltip>
                  <v-tooltip top v-else-if="item.status.startsWith('Send')">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text v-bind="attrs" v-on="on" :href="currentCalc.cloud_link+'?path=%2F'+selectedLanguage['link_cloud_text_1']+'%20'+item.number" target="_blank" style="text-decoration: none;">
                        <!-- <img src="/img/cloud.gif" alt="Loading" style="max-height: 10vh; max-width: 50px;"/> -->
                        <v-icon color="blue">mdi-cloud-arrow-right-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ selectedLanguage['tooltip_detailed_btn_text_2'] }}</span>
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="noHover" style="opacity: 0; cursor: unset;" text v-bind="attrs" v-on="on" :ripple="false">
                        <v-icon color="grey">
                          mdi-file-document-edit-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span></span>
                  </v-tooltip>
                  <v-tooltip top v-if="!item['optimization']">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text @click="optimizationHandler(item)" v-bind="attrs" v-on="on">
                        <v-icon color="grey">
                          mdi-transit-connection-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ selectedLanguage['tooltip_detailed_btn_text_2'] }}</span>
                  </v-tooltip>
                  <v-tooltip top v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text class="noHover" style="opacity: 0; cursor: unset;" v-bind="attrs" v-on="on" :ripple="false">
                        <v-icon color="grey">
                          mdi-transit-connection-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ selectedLanguage['tooltip_detailed_btn_text_2'] }}</span>
                  </v-tooltip>
                  <!-- <v-btn text disabled>
                    <v-icon color="grey">
                      mdi-land-plots-marker
                    </v-icon>
                  </v-btn> -->
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- Details dialog -->
    <v-dialog v-if="currentItem" v-model="confirmDetailDialog" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedLanguage['text_dialog_calc_text_1'] }}
        </v-card-title>
        <v-card-text style="font-weight: bold; font-size: 18px;"> {{ selectedLanguage['detail_text_dialog_1'] }} ₡{{ (-currentItem.calc_price).toLocaleString() }} ?</v-card-text> 
        <v-alert v-if="errorMessage" dense border="top" color="error" dark>
          {{ errorMessage }}
        </v-alert>
        <v-alert v-if="cloudPasswordInput" dense border="top" color="warning" dark>
          {{ selectedLanguage['detail_component_text11'] }}<br>
          <v-text-field v-model="cloudPassword" :rules="rules.password" clearable type="password" prepend-icon="mdi-lock" outlined placeholder="Enter your cloud password"/>
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="confirmDetailDialog=false">{{ selectedLanguage['detail_text_dialog_2'] }}</v-btn>
          <v-btn :color="color" :loading="loading1" dark variant="text" @click="clickDetailCalc(currentItem.number)">{{ selectedLanguage['detail_text_dialog_3'] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Optimization dialog -->
    <v-dialog v-if="currentItem" v-model="confirmOptimizatrionDialog" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedLanguage['text_dialog_calc_text_2'] }}
        </v-card-title>
        <v-card-text style="font-weight: bold; font-size: 18px;"> {{ selectedLanguage['detail_text_dialog_1'] }} ₡{{ (-currentItem.optimization_price).toLocaleString() }} ?</v-card-text> 
        <v-alert v-if="errorMessage" dense border="top" color="error" dark>
          {{ errorMessage }}
        </v-alert>
        <v-alert v-if="cloudPasswordInput" dense border="top" color="warning" dark>
          {{ selectedLanguage['detail_component_text11'] }}<br>
          <v-text-field v-model="cloudPassword" :rules="rules.password" clearable type="password" prepend-icon="mdi-lock" outlined placeholder="Enter your cloud password"/>
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="confirmOptimizatrionDialog=false">{{ selectedLanguage['detail_text_dialog_2'] }}</v-btn>
          <v-btn :color="color" :loading="loading1" dark variant="text" @click="optimizationMethod(currentItem.number)">{{ selectedLanguage['detail_text_dialog_3'] }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
  import Map from './Map.vue'
  import {apiAxios} from '../../helpers/interceptors'
  import NotificationMenu from '../../components/NotificationMenu.vue'
  import { portuguese } from '../../languages/pt-br'
  import { english } from '../../languages/en'

  export default {
    name: 'AdjustPrices',
    components:{Map,VuePdfEmbed,NotificationMenu},   
    computed: {
      steps () {
        return this.$store.getters.steps('railway')
      },
      status () {
        return this.$store.getters.status(this.$route.params.id)
      },
      ...mapGetters(['profile','baseLayers','balanceFormatted','currentCalc','currentExpropriation','currentCapex','currentSlope','alternativesDetail','alternatives']),
      srid () {
        try{
          if(this.currentCalc.srid){
            return this.currentCalc.srid
          }
          return 3857
        } catch (err){
          return 3857
        }
      },
      selectedLanguage() {
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        },
    },
    mounted: async function(){
      this.loadDetail()

      setTimeout(()=>{
        this.mapHeight = document.getElementById('div1').getElementsByTagName( 'div' )[0].offsetHeight
      document.getElementById('vl-map-mapHeightStyle').style.height = (this.mapHeight) + 'px';
      }, 1000)
      await this.initialConfigs()

    },
    data() {
      return {
        errorMessage: undefined,
        cloudPasswordInput: false,
        logoIconUrl: '/img/icons/logo_icon.webp',
        permanent: false,
        drawer: true,
        alternativesDetailIsOn: false,
        tab: 0,
        color: '#0A7373',
        loading1: false,
        loading2: false,
        loadingAlternativeReport: [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
        currentItem: undefined,
        confirmDetailDialog: false,
        baseLayerIdx: 4,
        compareAlternativesPdf: undefined,
        downloadingComparative: false,
        downloadingLayers: false,
        renderingComparative: false,
        confirmOptimizatrionDialog: false,
        rules: {
          password: [
            value => !!value || 'Please enter a password',
            (value) => !!value || 'Please type password.',
            (value) => (value && /[^A-Za-z0-9]/.test(value)) || 'At least one special character',
            (value) => (value && value.length > 9 ) || 'minimum 10 characters',
          ]
        },
        cloudPassword: undefined,
        alternativesHeaders: [
            { text: 'Name', value: 'name'},
            { text: 'Estimated Price (', value: 'priceFormatted'},
            { text: 'Length', value: 'lengthFormatted'},
            { text: 'Status', value: 'actions', align: 'center', sortable: false,},
            { text: 'Optimization', value: 'optimization', align: 'center', sortable: false,},
          ],
      };
    },
    methods: {
      updateLayersMap(){
        this.getLayers(this.$route.params.id)
      },
      isNumeric(value) {
        return value !== "" && !isNaN(value) && isFinite(value);
      },
      initialConfigs(){
        if(this.selectedLanguage['Detail_table_header_text'][1]['text'].includes('x1000')){
          // do nothing
        } else {
          this.selectedLanguage['Detail_table_header_text'][1]['text'] += this.currentCalc.currency+'x1000)'
        }

        this.alternativesHeaders = this.selectedLanguage['Detail_table_header_text']
      },
      async optimizationHandler(data){
        // console.log('data is ', data)
        this.currentItem=data
        this.confirmOptimizatrionDialog=true
      },
      async optimizationMethod(number){
        this.errorMessage = undefined
        this.loading1=true
        let res = await this.newAlternativeRoute({calcId: this.$route.params.id, alternativeNumber: number})
        if(res!=true){
          this.errorMessage = res
          this.loading1 = false
          return false
        }
        if(res==true){
          this.loadingAlternativeReport[number-1]=true
          this.getMonetize()
          this.confirmOptimizatrionDialog = false
          this.loading1=false
          this.currentItem=undefined
          await this.getAlternatives(this.$route.params.id)
          this.loadingAlternativeReport[number-1] = false
        }
      },
      async resendCloudMethod(param){

        var data = {
          'hash': this.currentCalc.id,
          'id': param
        }

        var res = await this.resendCloud(data)

        console.log('resp ', res)
      },
      startSetInterval(){
        this.alternativesDetailIsOn = true
        this.intervalIdFunc(
          window.setInterval(() => {
            this.getAlternatives(this.$route.params.id)
          }, 10000)
        )
      },
      redirectFunc(){
        if(process.env.VUE_APP_VAR_SELECT== 'prd'){
          this.$router.push({ name: 'billingMercadoPago' })
        } else {
          this.$router.push({ name: 'billing' })
        }
      },
      handleDocumentRender() {
        this.renderingComparative = false
      },
      handleClick(value) {
        if(!value.status){
          this.currentItem=value
          this.confirmDetailDialog=true
        }
      },
      async compareAlternatives(id){
        this.downloadingComparative = true
        this.errorMessage = undefined
        apiAxios.get("/download/"+id+this.selectedLanguage['detail_component_text14'], {responseType: "blob"})
        .then(res => {
          if(res.status==200){
            const blob = new Blob([res.data]);
            const objectUrl = URL.createObjectURL(blob);
            this.compareAlternativesPdf = objectUrl;
            this.downloadingComparative = false
            this.renderingComparative = true
            return true
          }
          else{
            this.errorMessage = res.statusText
            this.downloadingComparative = false
            return false
          }
        })
      },
      async loadDetail(){
        this.downloadingLayers = true
        this.errorMessage = undefined
        const getCurrentCalcPromise = this.getCurrentCalc(this.$route.params.id)
        const getLayersPromise = this.getLayers(this.$route.params.id)
        const getAlternativesPromise = this.getAlternatives(this.$route.params.id)
        await Promise.all([this.getCurrentCalc(this.$route.params.id),this.getLayers(this.$route.params.id),this.getAlternatives(this.$route.params.id)])
        let res = await getCurrentCalcPromise
        if(res!=true){
          this.errorMessage = res
          this.downloadingLayers = false
          return false
        }
        res = await getLayersPromise
        if(res!=true){
          this.errorMessage = res
          this.downloadingLayers = false
          return false
        }
        res = await getAlternativesPromise
        if(res!=true){
          this.errorMessage = res
          this.downloadingLayers = false
          return false
        }
        this.compareAlternatives(this.$route.params.id)
        this.downloadingLayers = false
      },
      async goStep(step){
        await this.changeStatus({calcId: this.$route.params.id, payload: {'status': 'Init','step_name': this.steps[step-1], 'step': step, 'notification': this.status.notification}})
      },
      ...mapActions(['newAlternativeRoute','resendCloud','getCurrentCalc','getLayers','updateCapex','changeStatus','detailedCalc','getAlternatives','useCredits','getMonetize','loginCloud','setCloudPassword','setInterval', 'intervalIdFunc']),
      save() {},
      cancel() {},
      open() {},
      close() {},
      capexItemStyle(item) {
        if(item.Unit!=0){ return 'capexLastLevel'}
        if(item.Level==0){ return 'capexZeroLevel'}
        if(item.Level==1){ return 'capexFirstLevel'}
        if(item.Level==2){ return 'capexSecondLevel'}
        if(item.Level==3){ return 'capexThirdLevel'}
        return ''
      },
      backToCalc() {
        this.$router.push('/type/railway/'+this.$route.params.id)
      },
      async clickCancelExit() {
        this.errorMessage = undefined
        this.loading2 = true
        const res = await this.goStep(1)
        if(res!=true){
          this.errorMessage = res
          this.loading2 = false
          return false
        }
        this.backToCalc()
        this.loading2 = false
      },
      async clickDetailCalc(number){
        this.errorMessage = undefined
        this.loading1=true
        let res = await this.useCredits({calcId: this.$route.params.id, alternativeNumber: number})
        if(res!=true){
          this.errorMessage = res
          this.loading1 = false
          return false
        }
        if(res==true){
          this.loadingAlternativeReport[number-1]=true
          this.getMonetize()
          this.confirmDetailDialog = false
          this.loading1=false
          this.currentItem=undefined
          await this.detailedCalc({calcId: this.$route.params.id, alternativeNumber: number})
          await this.getAlternatives(this.$route.params.id)
          this.loadingAlternativeReport[number-1] = false
        }
      },
    },
  }
</script>

<style scoped>
  .capexLastLevel {
    font-style: italic
  }
  .capexZeroLevel {
    background-color: #0A7373;
    font-weight: bold
  }
  .capexFirstLevel {
    background-color: #338D8C;
    font-weight: bold
  }
  .capexSecondLevel {
    background-color: #36bebc;
    font-weight: bold
  }
  .capexThirdLevel {
    background-color: #41e2df;
    font-weight: bold
  }
  .nominatim{
  display: none !important;
}
.noHover{
    pointer-events: none;
}
</style>