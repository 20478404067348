<template>
  <v-container pa-0 fluid>
    <v-app-bar v-if="status && status.step!=7 && status.step!=8" app clipped-left :color="color" dense dark id="MenuOnTop">
      <v-app-bar-nav-icon to="/"><v-img :src="logoIconUrl" width="10px" alt="wwcalc"/></v-app-bar-nav-icon>
      <v-btn :loading="loading2" text @click="clickCancelExit()"><v-icon>mdi-chevron-left</v-icon>{{ selectedLanguage['map_component_text1'] }}</v-btn>
      <v-spacer/>
      <!-- LAYER MENU -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text dark v-bind="attrs" v-on="on"><v-icon>mdi-layers-triple-outline</v-icon>{{ selectedLanguage['map_component_text2'] }}</v-btn>
        </template>
        <v-list dense>
          <v-subheader>{{ selectedLanguage['map_component_text3'] }}</v-subheader>
            <v-list-item-group v-model="localbaseLayerIdx" color="primary">
              <v-list-item v-for="(item, i) in baseLayers" :key="i">
                <v-list-item-icon>
                  <v-icon>mdi-checkerboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"/>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
        </v-list>
      </v-menu>
      <v-spacer/>
      <div id="SRID">
        <v-select
          v-model="inputSRIDSelected"
          :items="inputSRIDList"
          style="align-self: center;"
          density="compact"
          hide-details
          outlined
          v-on:change="saveSRID"
        ></v-select>
      </div>
      <v-spacer/>
      <!-- FORM MENU -->
      <v-menu v-if="inputPointEnable" v-model="inputPointMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                dark
                v-bind="attrs"
                v-on="on"
              >
              {{ selectedLanguage['map_component_text4'] }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <UploadButtonPointsVue v-if="inputPointEnable" @inputVector="addVector"/>
              </v-list-item>
              <v-list-item>
                <v-btn text dark v-bind="attrs" v-on="on" style="color: black !important;">
                  <v-icon>mdi-vector-point-plus</v-icon>{{ selectedLanguage['map_component_text5'] }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-select v-model="inputSRIDSelected" outlined :items="inputSRIDList" :label="selectedLanguage['map_component_text6']"/>
            </v-list-item>
            <v-list-item>
              <v-select v-model="coordinateFormatSelected" outlined :items="coordinateFormatList" :label="selectedLanguage['map_component_text7']"/>
            </v-list-item>
            <v-list-item v-if="coordinateFormatSelected=='GeoJson'">
              <v-textarea v-model="inputGeoJson" outlined :label="selectedLanguage['map_component_text8']"/>
            </v-list-item>
            <v-list-item v-if="coordinateFormatSelected=='Wkt'">
              <v-textarea v-model="inputWkt" outlined :label="selectedLanguage['map_component_text9']"/>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="inputPointMenu = false">
              <v-icon>mdi-close-circle</v-icon>{{ selectedLanguage['map_component_text10'] }}
            </v-btn>
            <v-btn color="primary" text @click="addPoints()">
              <v-icon>mdi-plus-box</v-icon>{{ selectedLanguage['map_component_text11'] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-spacer v-if="inputPointEnable"/>
      <v-menu v-if="inputPolylineEnable" v-model="inputPolylineMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text dark v-bind="attrs" v-on="on">
            <v-icon>mdi-vector-polyline-plus</v-icon>{{ selectedLanguage['map_component_text12'] }}
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-textarea name="inputWKT" outlined :label="selectedLanguage['map_component_text13']" auto-grow 
                  value="SRID=4326;MULTILINESTRING ((10 10, 20 20, 10 40),(40 40, 30 30, 40 20, 30 10))" hint="Hint text"/>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="inputPolylineMenu = false">
              {{ selectedLanguage['map_component_text10'] }}
            </v-btn>
            <v-btn color="primary" text @click="inputPolylineMenu = false">
              <v-icon>mdi-plus-box</v-icon>{{ selectedLanguage['map_component_text11'] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-spacer v-if="inputPolylineEnable"/>
      <v-menu v-if="inputPolygonEnable" v-model="inputPolygonMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text dark v-bind="attrs" v-on="on">
            <v-icon>mdi-shape-polygon-plus</v-icon>{{ selectedLanguage['map_component_text14'] }}
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-textarea name="inputWKT" outlined :label="selectedLanguage['map_component_text13']" auto-grow 
                  value="SRID=4326;MULTIPOLYGON (((40 40, 20 45, 45 30, 40 40)),((20 35, 10 30, 10 10, 30 5, 45 20, 20 35),(30 20, 20 15, 20 25, 30 20)))" hint="Hint text"/>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="inputPolygonMenu = false">
              {{ selectedLanguage['map_component_text10'] }}
            </v-btn>
            <v-btn color="primary" text @click="inputPolygonMenu = false">
              <v-icon>mdi-plus-box</v-icon>{{ selectedLanguage['map_component_text11'] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-spacer v-if="inputPolygonEnable"/>
      <UploadButtonVue v-if="inputUploadFileEnable" @inputVector="addVectorPolygon"/>
      <v-spacer v-if="inputUploadFileEnable"/>
      <v-menu v-if="inputSearchEnable" disabled v-model="inputSearchMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text dark v-bind="attrs" v-on="on" @click="teste()">
            <v-icon>mdi-map-search-outline</v-icon>{{ selectedLanguage['map_component_text15'] }}
          </v-btn>
        </template>
        <v-text-field outlined v-model="placeName" :label="selectedLanguage['map_component_text16']"/>
      </v-menu>
      <v-spacer v-if="inputSearchEnable"/>
      <v-btn text @click="clearMap2()"><v-icon>mdi-backspace-outline</v-icon>{{ selectedLanguage['map_component_text17'] }}</v-btn>
      <v-spacer/>
      <v-btn v-if="showCurvesForm === true" :loading="loading1" text @click="clickSaveExit()">{{ selectedLanguage['map_component_text18'] }}<v-icon>mdi-chevron-right</v-icon></v-btn>
      <v-btn v-if="showCurvesForm === false" :loading="loading1" text @click="clickSaveExit()">{{ selectedLanguage['map_component_text18'] }}<v-icon>mdi-chevron-right</v-icon></v-btn>
    </v-app-bar>

      <!-- props ignore vector -->
      <div v-if="showPropsForm === true" style=" display: flex; position: absolute; top: 0; right: 0; z-index: 1; background-color: white; height: 90.3vh; width: 26vw;">
        <div class="px-3 py-2">
          <v-sheet width="100%" class="mx-auto">
            <v-form fast-fail @submit.prevent id="propsform" >
              <div v-for="(prop, i) in props" :key="i">
                <div v-if="i == 'name'">
                  <v-text-field
                  style="width: 25vw"
                    v-model="props[i]"
                    :label="i"
                    :name="i"
                  ></v-text-field>
                </div>
                <div v-if="i == 'value' && props['value'] == '0'">
                  <p style="color: red">{{ selectedLanguage['map_component_text19'] }}</p>
                </div>
                <div v-if="i == 'value' && props['value'] != '0'">
                  <v-checkbox v-model="ignoreStatus" :label="selectedLanguage['map_component_text20']"></v-checkbox>
                </div>
              </div>
            </v-form>
            <v-btn type="submit" @click="updateVectorProps()" block class="mt-2">{{ selectedLanguage['map_component_text21'] }}</v-btn>
          </v-sheet>
        </div>
      </div>

      <!-- props alternative name -->
      <div v-if="showPropsForm2 === true" style=" display: flex; position: absolute; top: 0; right: 0; z-index: 1; background-color: white; height: 90.3vh; width: 26vw;">
        <div class="px-3 py-2">
          <v-sheet width="100%" class="mx-auto">
            <v-form fast-fail @submit.prevent id="propsform" >
              <div v-for="(prop, i) in props" :key="i">
                <div v-if="i == 'name'">
                  <v-text-field
                  style="width: 25vw"
                    v-model="props[i]"
                    :label="i"
                    :name="i"
                  ></v-text-field>
                  <v-btn style="width: 100%; margin-bottom: 20px;" :color="props['style']['strokeColor']"></v-btn>
                  <!-- <div style="width: 100%; height: 30px; background-color: 'props['style']['strokeColor']';">{{ props['style']['strokeColor'] }}</div> -->
                </div>
                <div v-if="i == 'value' && props['value'] == '0'">
                  <p style="color: red">{{ selectedLanguage['map_component_text19'] }}</p>
                </div>
                <div v-if="i == 'value' && props['value'] != '0'">
                  <v-checkbox v-model="ignoreStatus" :label="selectedLanguage['map_component_text20']"></v-checkbox>
                </div>
              </div>
            </v-form>
            <v-btn type="submit" @click="exitAlternativeName()" block class="mt-2">{{ selectedLanguage['map_component_text21_2'] }}</v-btn>
          </v-sheet>
        </div>
      </div>

      <!-- send curves -->

      <div v-if="showCurvesForm === true"
      id="curveforms"
      style="
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      background-color: white;
      height: 91vh;
      width: 26vw;"
      >
        <div class="px-3 py-2" style="width: 100%;">
          <v-sheet width="100%" class="mx-auto" style="padding-bottom: 30px;">
            <v-form fast-fail @submit.prevent id="propsform" >
              <div >
                <div style="margin-top: 20px; margin-bottom: 5px">

                  <v-textarea
                  width="100%"
                  v-model="curvesList"
                  :label="selectedLanguage['map_component_text22']"
                  name="CurvesList"
                  hint="[[350,80],[1000,30],[350,80]]"
                  >

                  </v-textarea>
                </div>
              </div>
            </v-form>
            <v-btn type="submit" @click="saveCurvesList()" block class="mt-2" style="margin-bottom: 60px;">{{ selectedLanguage['map_component_text23'] }}</v-btn>
          </v-sheet>
        </div>
      </div>
    
    <vl-map ref="map" :load-tiles-while-animating="true" :load-tiles-while-interacting="true" :wrap-x="false" :data-projection="projection" id="mapHeightStyle" style="height: 86vh">
      <vl-view ref="mapView" :min-zoom="2" :wrap-x="false" :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation" :data-projection="projection"/>

      <vl-layer-tile v-if="baseLayer!==undefined" :id="baseLayer.id"  :z-index="0">
        <component :is="'vl-source-' + baseLayer.name" v-bind="baseLayer"/>
      </vl-layer-tile>

      <vl-layer-vector id="drawLayer" :z-index="1">
        <vl-source-vector ident="drawTarget" :features.sync="features" :wrap-x="false"/>
      </vl-layer-vector>
      <vl-interaction-draw ref="interactionDraw" v-if="internalDrawType!=undefined" source="drawTarget" :type="internalDrawType"/>

      <vl-layer-vector v-if="internalDrawType==undefined" id="editLayer" :z-index="5">
        <vl-source-vector ident="editTarget" :features.sync="featuresSelected" :wrap-x="false"></vl-source-vector>
      </vl-layer-vector>

      <vl-interaction-select v-if="internalDrawType==undefined" :features.sync="featuresSelected" source="editTarget"/>

      <!-- geojson -->
      <div v-if="isGeometry != true">
        <div v-for="layer in layers" :key="layer.id" :id="layer.id">
          <vl-layer-vector :visible="layer.visible" :zIndex="layer.zIndex">
            <vl-source-vector :ref="layer.name" :wrap-x="false">
              <vl-feature :properties="layer">
                <component :is="geometryTypeToCmpName(layer.geojson.type)" v-if="layer.geojson!=null" :coordinates="layer.geojson.coordinates" />
              </vl-feature>
            </vl-source-vector>
            <vl-style-box v-if="layer.style">
              <vl-style-circle :radius="5">
                <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
                <vl-style-fill :color="layer.style.fillColor"/>
              </vl-style-circle>
              <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
              <vl-style-fill :color="layer.style.fillColor"/>
            </vl-style-box>
          </vl-layer-vector>
        </div>
      </div>

      <!-- Linestring -->
      <div v-if="isGeometry != true && status.step<8">
        <div v-for="layer in [corridor_1]" :key="layer.id" :id="layer.id">
          <vl-layer-vector :visible="layer.visible" :zIndex="layer.zIndex">
            <vl-source-vector :ref="layer.name" :wrap-x="false">
              <vl-feature :properties="layer">
                <component :is="geometryTypeToCmpName(layer.geojson.type)" v-if="layer.geojson!=null" :coordinates="layer.geojson.coordinates" />
              </vl-feature>
            </vl-source-vector>
            <vl-style-box v-if="layer.style">
              <vl-style-circle :radius="5">
                <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
                <vl-style-fill :color="layer.style.fillColor"/>
              </vl-style-circle>
              <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
              <vl-style-fill :color="layer.style.fillColor"/>
            </vl-style-box>
          </vl-layer-vector>
        </div>
      </div>

      <!-- buffer -->
      <div v-if="toggle === true && isGeometry === true">
        <div v-if="layers">
          <div v-for="(layer, index) in layers" :key="index" :id="index">
            <vl-layer-vector :visible="true" :zIndex="1">
              <vl-source-vector ref="buffer" :wrap-x="false">
                <vl-feature :properties="layer">
                  <component :is="geometryTypeToCmpName(layer.geojson.type)" v-if="layer.geojson!=null" :coordinates="layer.geojson.coordinates" />
                </vl-feature>
              </vl-source-vector>
              <vl-style-box v-if="layer.style">
                <vl-style-circle :radius="5">
                  <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
                  <vl-style-fill :color="layer.style.fillColor"/>
                </vl-style-circle>
                <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
                <vl-style-fill :color="layer.style.fillColor"/>
              </vl-style-box>
            </vl-layer-vector>
          </div>
        </div>
      </div>

      <!-- limit area -->
      <div v-if="toggleLimitArea === true">
        <div v-for="(layer, index) in layerLimit" :key="index" :id="index">
          <vl-layer-vector :visible="true" :zIndex="1">
            <vl-source-vector ref="limit" :wrap-x="false">
              <vl-feature :properties="layer">
                <component :is="geometryTypeToCmpName(layer.geojson.type)" v-if="layer.geojson!=null" :coordinates="layer.geojson.coordinates" />
              </vl-feature>
            </vl-source-vector>
            <vl-style-box v-if="layer.style">
              <vl-style-circle :radius="5">
                <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
              </vl-style-circle>
              <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
            </vl-style-box>
          </vl-layer-vector>
        </div>
      </div>

      <!-- Points step 7 -->
      <div v-if="togglePoint === true">
        <div v-for="(layer, index) in layerPoint" :key="index" :id="index">
          <vl-layer-vector :visible="true" :zIndex="1">
            <vl-source-vector ref="limit" :wrap-x="false">
              <vl-feature :properties="layer">
                <component :is="geometryTypeToCmpName(layer.geojson.type)" v-if="layer.geojson!=null" :coordinates="layer.geojson.coordinates" />
              </vl-feature>
            </vl-source-vector>
            <vl-style-box v-if="layer.style">
              <vl-style-circle :radius="5">
                <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
              </vl-style-circle>
              <vl-style-stroke :color="layer.style.strokeColor" :width="Number(layer.style.strokeWidth)"/>
            </vl-style-box>
          </vl-layer-vector>
        </div>
      </div>

      <!-- geometry -->
      <div v-if="isGeometry === true" id="vetores">
        <div v-for="(layerIndex, index) in layersIndex" :key="index">
          <div v-if="layersGeometry[layerIndex]">
            <div v-for="(layer, i) in layersGeometry[layerIndex][0].geoms" :key="i" :id="i+layerIndex">
              <vl-layer-vector :visible="layersGeometry[layerIndex][0].visible" :zIndex="1">
                <vl-source-vector :ref="layersGeometry[layerIndex][0].type" :wrap-x="false">
                  <vl-feature :properties="layer.properties" @click="vectorIdentifyer(layersGeometry[layerIndex], layer.geoms, layer.properties)">
                    <component :is="geometryTypeToCmpName(layer.geometry.type)" v-if="layer.geometry!=null" :coordinates="layer.geometry.coordinates" />
                  </vl-feature>
                </vl-source-vector>
                <vl-style-box v-if="layersGeometry[layerIndex][0].color">
                  <vl-style-circle :radius="5">
                    <vl-style-stroke :color="layersGeometry[layerIndex][0].color" :width="Number(layersGeometry[layerIndex][0].strokeWidth)"/>
                    <vl-style-fill :color="layersGeometry[layerIndex][0].color"/>
                  </vl-style-circle>
                  <vl-style-stroke :color="layersGeometry[layerIndex][0].color" :width="Number(layersGeometry[layerIndex][0].strokeWidth)"/>
                  <vl-style-fill :color="layersGeometry[layerIndex][0].color"/>
                </vl-style-box>
              </vl-layer-vector>
            </div>
          </div>
        </div>
      </div>
    </vl-map>
  </v-container>
</template>

<script>
import "ol/ol.css";
import { kebabCase } from 'lodash'
import SearchNominatim from 'ol-ext/control/SearchNominatim.js'
import WKT from 'ol/format/WKT.js'
import GeoJSON from 'ol/format/GeoJSON.js'
import { mapGetters } from 'vuex'
import proj4 from 'proj4'
import {register} from 'ol/proj/proj4.js'
import { mapActions } from 'vuex'
import { pointsListToEWKT, polygonsListToEWKT } from '../../helpers/map.js'
import { transform, transformExtent, fromLonLat } from 'ol/proj'
import { boundingExtent } from 'ol/extent'
import UploadButtonVue from '../../components/UploadButton.vue'
import UploadButtonPointsVue from '../../components/UploadButtonPoints.vue'
import UploadButtonLineStringVue from '../../components/UploadButtonLineString.vue'
import store from '../../store'
import { portuguese } from '../../languages/pt-br'
import { english } from '../../languages/en'

import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

const pointWktExample = 'POINT (-5143623.61 -1983164.039)\nPOINT (-5142580.146 -1972944.234)'
const pointGeoJsonExample = '{"type": "Point", "coordinates": [ -5143623.61, -1983164.039]}\n{"type": "Point", "coordinates": [-5142580.146, -1972944.234]}'

export default {
  name: 'Map',
  components:{UploadButtonVue,
    UploadButtonPointsVue,
    UploadButtonLineStringVue,
  },
  computed: {
    steps () {
      return this.$store.getters.steps('railway')
    },
    baseLayer () {
      return this.$store.getters.baseLayer(this.localbaseLayerIdx)
    },
    status () {
      return this.$store.getters.status(this.$route.params.id)
    },
    projection () {
      if(window.localStorage.getItem('CurrentSRID') != null){
        return window.localStorage.getItem('CurrentSRID')
      } else {
        return 'EPSG:'+this.srid
      }
    },
    ...mapGetters(['currentCalc','profile','lineStringDataResp','storagedProps','alternativePolygon','baseLayers','limit','sridDict','track', 'updateVectorsByType', 'corridor_1']),
    selectedLanguage() {
      if (this.profile['language'] === 'en') {
        return english;
      } else {
        return portuguese;
      }
    },
  },
  props: {
    initZoom: Boolean,
    drawType: String,
    layers: Array,
    layersIndex: Array,
    layersGeometry: undefined,
    isGeometry: Boolean,
    toggle: Boolean,
    layerLimit: Array,
    layerPoint: Array,
    toggleLimitArea: Boolean,
    togglePoint: Boolean,
    srid: {
      default: 3857,
      type: Number
    },
    baseLayerIdx: {
      default: 4,
      type: Number
    },
    inputPointEnable: {
      default: false,
      type: Boolean
    },
    inputPolylineEnable: {
      default: false,
      type: Boolean
    },
    inputPolygonEnable: {
      default: false,
      type: Boolean
    },
    inputUploadFileEnable: {
      default: false,
      type: Boolean
    },
    inputSearchEnable: {
      default: false,
      type: Boolean
    },
  },
  created(){

    setTimeout(()=>{
      this.mapHeight = document.getElementById('div1').getElementsByTagName( 'div' )[0].offsetHeight
      document.getElementById('vl-map-mapHeightStyle').style.height = (this.mapHeight) + 'px';
    }, 1000)

  },
  async mounted(){

    if(window.localStorage.getItem('CurrentSRID') != null){
      this.inputSRIDSelected = window.localStorage.getItem('CurrentSRID')
    }

    Object.keys(this.sridDict).forEach(key => {
      this.inputSRIDList.push('EPSG:'+key)
      proj4.defs('EPSG:'+key,
        this.sridDict[key])
      register(proj4)
    })

    if(this.initZoom){this.zoomToLimit(this.track.geojson.coordinates)}
    if(this.$store.getters.status(this.$route.params.id).step <= 5 ){
      this.getLayers(this.$route.params.id)
      this.searchZoom()
    }
    if(this.$store.getters.status(this.$route.params.id).step > 5 ){
      this.clearAtHome()
    }

    this.initMapConfig()

    window.addEventListener("resize", this.changeMapSize)

  },
  // destroyed(){
  //   window.addEventListener("resize", this.changeMapSize)
  // },
  data() {
    return {
      inputSRIDList: ['EPSG:3857'],
      inputSRIDSelected: 'EPSG:3857',
      coordinateFormatList: ['GeoJson','Wkt'],
      coordinateFormatSelected: 'GeoJson',
      inputWkt: pointWktExample,
      inputGeoJson: pointGeoJsonExample,
      showCurvesForm: false,
      inputPointMenu: false,
      saveLineString: undefined,
      lineStringData: undefined,
      importOn: false,
      ThereIsLS: false,
      currentLSCount: 2,
      curvesList: undefined,
      formheight: 0,
      inputPolylineMenu: false,
      inputPolygonMenu: false,
      inputSearchMenu: false,
      loading1: false,
      loading2: false,
      internalDrawType: this.drawType,
      color: '#0A7373',
      logoIconUrl: '/img/icons/logo_icon.webp',
      area: undefined,
      ignoreStatus: false,
      considerStatus: false,
      showPropsForm: false,
      showPropsForm2: false,
      props: undefined,
      crs: undefined,
      curvesListLength: 0,
      searchBtnToggle: true,
      // urlTest: "https://dev.wwcalc.com/spreadsheet_report/tiles/{z}/{x}/{y}.mvt",
      urlTest: "https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/tile/{z}/{y}/{x}.pbf",
      features: [],
      featuresSelected: [],
      zoom: 3,
      center: [0, 0],
      rotation: 0,
      placeName: "",
      localbaseLayerIdx: 4,
    };
  },
  methods: {

    changeMapSize (){

      document.getElementById('vl-map-mapHeightStyle').style.height = '300px';

      setTimeout(()=>{
        this.mapHeight = document.getElementById('div1').getElementsByTagName( 'div' )[0].offsetHeight
        document.getElementById('vl-map-mapHeightStyle').style.height = (this.mapHeight) + 'px';
      }, 1000)

      this.$refs.map.$createPromise.then(() => {
          setTimeout(()=>{
            this.$refs.map.$map.updateSize()
          }, 2000)
        })

        // console.log('is being called, height is: ', this.mapHeight, 'original height is: ', this.originalheight)

    },

    saveCurvesList(){

      if(this.curvesList != undefined && this.curvesList != null ){

        let arrayDados = []
        var xyArr = []

          let regex = /\d+\.\d+|\d+/g
          let matches = this.curvesList.match(regex)
          
          for(let i = 0; i < matches.length; i++){
            xyArr.push(matches[i].split(',',1))
          }
  
          arrayDados = []
          for (let i = 0; i < xyArr.length; i += 2) {
              let x = parseFloat(xyArr[i])
              let y = parseFloat(xyArr[i+1])
              arrayDados.push([x, y])
          }
        if(arrayDados.length != this.curvesListLength){
          window.alert(this.selectedLanguage['alert_text_list_curves_txt_1']+arrayDados.length+this.selectedLanguage['alert_text_list_curves_txt_2']+this.curvesListLength)
        } else {
          for( let i=0; i < arrayDados.length; i++ ){
            if(arrayDados[i][0] < 0){
              window.alert("The curve spiral can't be lower than 0")
              return
            } else if (arrayDados[i][1] < 0){
              window.alert("The radius can't be lower than 0")
              return
            }
          }
          this.lineStringData['info'] = { 'list_curves': (this.curvesList.replaceAll('\n', ''))}

          document.getElementById('curveforms').style.visibility = "hidden";
          this.clickSaveExit()
        }
      } else {
        this.clickSaveExit()
      }

    },

    async initMapConfig(){
      await this.$refs.map.$createPromise.then(() => {

        setTimeout(()=>{
            var centerMap = this.$refs.map.$map.getView().getCenter()
            if(centerMap){
              this.center = centerMap
            }
            this.$refs.map.$map.updateSize()
          }, 500)
      })
    },

    saveSRID(){
      window.localStorage.setItem('CurrentSRID', this.inputSRIDSelected)
      this.$router.go(this.$router.currentRoute)
      // window.location.reload()
    },
    hideSearch(){
      document.getElementsByClassName('nominatim')[0].style.display = "none";
    },
    tilesProps(){
      this.$refs.map.$createPromise.then(() => {
        var mapRef = this.$refs.map.$map
        mapRef.on('pointermove', showInfo);
        const info = document.getElementById('info');
        function showInfo(event) {
          const features = mapRef.getFeaturesAtPixel(event.pixel);
            if (features.length == 0) {
              info.innerText = '';
              info.style.opacity = 0;
              return;
            }
            const properties = features[0].getProperties();
            info.innerText = JSON.stringify(properties, null, 2);
            info.style.opacity = 1;
          }
        }
      )
    },
    toggleSearchShow(){
      document.getElementsByClassName('nominatim')[0].style.display = "block";
      this.searchBtnToggle = true
    },
    toggleSearchHide(){
      document.getElementsByClassName('nominatim')[0].style.display = "none";
      this.searchBtnToggle = false
    },
    vectorIdentifyer(featuresSelected){
      if(featuresSelected.length > 0 && this.status.step != 4 && this.status.step != 5 && featuresSelected[0]['properties'] && featuresSelected[0]['properties']['name']){
        var name = featuresSelected[0]['properties']['name']
        name.toString()
        if(name == 'buffer'){
          this.showPropsForm = false
        }else if(name.includes('alterna')){
          this.showPropsForm2 = true
          this.props = featuresSelected[0]['properties']
        } else {
          this.showPropsForm = true
          this.props = featuresSelected[0]['properties']
          this.crs = store.getters.vectors[featuresSelected[0]['properties']['gpkg']][0]['vector']['crs']
        }
      } else {
        this.showPropsForm = false
      }
      return
    },
    async updateVectorProps(){

      if(this.ignoreStatus === true){
        this.props['value'] = 0
        this.featuresSelected[0]['properties']['value'] = 0
      }

        var obj = {"type": "Feature","properties": this.props, "geometry": this.featuresSelected[0]["geometry"]}

        await this.updateStoragedProps({vectorType: this.featuresSelected[0]['properties']['gpkg'], obj: obj})
        this.showPropsForm = false
        await this.changeStatus({calcId: this.$route.params.id, payload: {"status": "HasChanges", "step_name": "Calculate alternatives", "step": 7}})
        this.featuresSelected = []
    },

    async exitAlternativeName(){
      this.showPropsForm2 = false
      this.featuresSelected = []
    },

    addPoints(){
      const formatOut = new GeoJSON() 
      let formatIn
      let inputArray
      let coordinatesList = []
      if(this.coordinateFormatSelected=='GeoJson'){
        inputArray = this.inputGeoJson.split("\n");
        formatIn = new GeoJSON() 
      }else{
        inputArray = this.inputWkt.split("\n");
        formatIn = new WKT();
      }
      try {
        inputArray.forEach(line => {
          let feature = formatIn.readFeature(line, {
            dataProjection: this.inputSRIDSelected,
            featureProjection: this.inputSRIDSelected,
          })
          var featureDict = JSON.parse(formatOut.writeFeature(feature))
          if(this.inputSRIDSelected=='Lat/Lon'){
            featureDict.geometry.coordinates = fromLonLat(featureDict.geometry.coordinates)
          }else{
            featureDict.geometry.coordinates = transform(featureDict.geometry.coordinates, this.inputSRIDSelected, this.projection)
          }
          coordinatesList.push(featureDict.geometry.coordinates)
          this.features.push(featureDict)
        })
      } catch {
        alert('Please, insert a valid point list of geometries.')
      }
      
      this.zoomToLimit(coordinatesList)
      this.inputPointMenu = false
    },
    async addVector(vector){

      this.features = vector.features

      if(this.features[0].geometry.type == "MultiPolygon" || this.features[0].geometry.type == "Polygon"){
        this.features = []

        this.$refs.map.$map.getLayers().forEach(layer => {
          if (layer instanceof VectorLayer) {
              layer.getSource().clear()
          }
      });

      this.$refs.map.$map.updateSize()
      this.features = []
      this.featuresSelected = []
      this.showCurvesForm = false
      this.importOn = false

      }

      if(this.features[0].geometry.type == "MultiPoint" || this.features[0].geometry.type == "Point"){
        this.showCurvesForm = false
        this.importOn = true
        this.zoomToLimit(this.features[0].geometry.coordinates)
        this.features = vector.features

        var novoArray = [];

        let i;
        for(i=0;i<vector.features[0].geometry.coordinates.length;i++){
          novoArray.push(
            {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiPoint", "coordinates": [[vector.features[0].geometry.coordinates[i][0]],[vector.features[0].geometry.coordinates[i][1]]]
              }
            }
          )

        }

        setTimeout(()=>{
          this.features = novoArray
        },1500)

      }

      if(this.features[0].geometry.type == "MultiLineString" || this.features[0].geometry.type == "LineString"){

        let arrB = [
          { "type": "Feature", "properties": { "name": null }, "geometry": { "type": "LineString", "coordinates": [[]] } }
        ]

        for(let i = 0; i < vector.features.length; i++ ){
        if(vector.features[i].geometry.coordinates.length <= 0){
          //do nothing
        } else if (vector.features[i].geometry.coordinates[0].length <= 0){
          //do nothing
        } else if(vector.features[i].geometry.type === "LineString"){
          arrB[0].geometry.coordinates[0] = vector.features[i].geometry.coordinates
          this.features = arrB
        } else{
          //do nothing
          this.features = vector.features
        }
      }

        this.ThereIsLS = true

        this.saveLineString = this.features[0].geometry.coordinates

        var first;
        var last;

        if(this.features[0].geometry.type == "MultiLineString"){
          first = this.features[0].geometry.coordinates[0][0].slice(0,2)
          last = this.features[0].geometry.coordinates[0].at(-1).slice(0,2)
        } else {
          first = this.features[0].geometry.coordinates[0][0].slice(0,2)
          last = this.features[0].geometry.coordinates[0].at(-1).slice(0,2)
        }

        this.curvesListLength = (this.features[0].geometry.coordinates[0].length-2)

        this.importOn = true

        var arrLS = []


        for(let i = 0; i < this.saveLineString[0].length; i++){
          arrLS.push((((this.saveLineString[0][i]).toString()).replace(',', ' ').replace(',', ' ')))
        }

        var tratarLineString = arrLS.toString()

        var LSsrid = 'SRID='+(this.projection.replace('EPSG:',''))+';MULTILINESTRING Z (('

        var LSFinal = LSsrid+tratarLineString+'))'

        this.lineStringData = {
          "geom": LSFinal,
          "alternative_id": "1",
          "info": {}
        }

        var newPoints = [{
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiPoint", "coordinates": [[first[0]],[first[1]]]
              }
          },
          {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "MultiPoint", "coordinates": [[last[0]],[last[1]]]
              }
          }
        ]

        this.features = newPoints

        this.showCurvesForm = true

        this.adicionarLs(vector)

        } else {
          return
        }

    },

    async addVectorPlus(vector){

    if(vector.features[0].geometry.type == "MultiPolygon" || vector.features[0].geometry.type == "Polygon"){
      // do nothing
    }

    if(vector.features[0].geometry.type == "MultiPoint" || vector.features[0].geometry.type == "Point"){
      // do nothing
    }

    if(vector.features[0].geometry.type == "MultiLineString" || vector.features[0].geometry.type == "LineString"){

      let arrB = [
        { "type": "Feature", "properties": { "name": null }, "geometry": { "type": "LineString", "coordinates": [[]] } }
      ]

      for(let i = 0; i < vector.features.length; i++ ){
      if(vector.features[i].geometry.coordinates.length <= 0){
        //do nothing
      } else if (vector.features[i].geometry.coordinates[0].length <= 0){
        //do nothing
      } else if(vector.features[i].geometry.type === "LineString"){
        arrB[0].geometry.coordinates[0] = vector.features[i].geometry.coordinates
        vector.features = arrB
      } else{
        //do nothing
      }
    }

      this.saveLineString = vector.features[0].geometry.coordinates

      this.curvesListLength = (vector.features[0].geometry.coordinates[0].length-2)

      var arrLS = []


      for(let i = 0; i < this.saveLineString[0].length; i++){
        arrLS.push((((this.saveLineString[0][i]).toString()).replace(',', ' ').replace(',', ' ')))
      }

      var tratarLineString = arrLS.toString()

      var LSsrid = 'SRID='+(this.projection.replace('EPSG:',''))+';MULTILINESTRING Z (('

      var LSFinal = LSsrid+tratarLineString+'))'

      this.lineStringData.push({
        "geom": LSFinal,
        "alternative_id": this.currentLSCount,
        "info": {}
      })

      this.currentLSCount = this.currentLSCount+1

      this.showCurvesForm = true

      this.adicionarLs(vector)

      } else {
        return
      }

    },

    adicionarLs(vectorData) {
      try {
        var format = new GeoJSON();
        var features = format.readFeatures(vectorData, {
          dataProjection: this.projection,
          featureProjection: 'EPSG:3857'
        });

        var vectorSource = new VectorSource({
          features: features
        });

        var vectorLayer = new VectorLayer({
          source: vectorSource
        });

        this.$refs.map.$map.addLayer(vectorLayer);
        this.$refs.map.$map.getView().fit(vectorSource.getExtent(), this.$refs.map.$map.getSize());
      } catch (error) {
        console.log('Error')
      }
    },

    addVectorPolygon(vector){

      if(vector.features[0].geometry.type == "MultiPoint" || vector.features[0].geometry.type == "Point"){
        this.features = []
      }else if(vector.features[0].geometry.type == "MultiLineString" || vector.features[0].geometry.type == "LineString"){
        this.features = []
      }else {
        for(let i = 0; i < vector.features.length; i++ ){
        if(vector.features[i].geometry.coordinates[0].length <= 0){
          //do nothing
        }else if(vector.features[i].geometry.coordinates[0][0].length <= 0){
          //do nothing
        } else if(vector.features[i].geometry.type === 'Polygon'){
          vector.features = [vector.features[i]]
        } else {
          vector.features[i].geometry.coordinates = vector.features[i].geometry.coordinates[0]
          vector.features[i].geometry.type = "Polygon"
          vector.features = [vector.features[i]]
        }
      }
      this.features = vector.features
      }

    },

    searchZoom: async function () {
      this.$refs.map.$createPromise.then(() => {
        var mapRef = this.$refs.map.$map

        setTimeout(()=>{
          this.$refs.map.$map.updateSize()
        }, 1500)
        var search = new SearchNominatim(
          {   
            // target: $(".options").get(0),
            // polygon: $("#polygon").prop("checked"),
            // reverse: true,
            // position: true, // Search, with priority to geo position
            // title: "Busca",
            // reverseTitle: "Clique no mapa...",
            // placeholder: "Busca..."
          });
        this.$refs.map.$map.addControl(search);
        search.on('select', function(e)
        {
          if (e.search.geojson) {
            var format = new GeoJSON();
            var f = format.readFeature(e.search.geojson, { dataProjection: "EPSG:4326", featureProjection: this.$refs.map.$map.getView().getProjection() });
            this.$refs.mapView.$view.fit(
              f.getGeometry().getExtent(),
              {
                size: this.$refs.map.$map.getSize(),
                duration: 1000,
              },
            )
          }
          else{
            mapRef.getView().animate({
                center:e.coordinate,
                zoom: Math.max (mapRef.getView().getZoom(),16)
              });
            }
            search.clearHistory()
        });
        search.clearHistory()
      })
    },
    zoomToLimit: function (coordinates) {
      setTimeout(() => {
        let ext = boundingExtent(coordinates)
        ext = transformExtent(ext, this.projection, "EPSG:3857");
        this.$refs.mapView.$view.fit(ext, {
          size: this.$refs.map.$map.getSize(),
          duration: 1000,
          projection: this.projection,
          maxZoom: 12,
        })
      }, 1000)
    },
    geometryTypeToCmpName(type) {
      let name = 'vl-geom-' + kebabCase(type)
      return name
    },
    clearMap2(){
      this.features = []
    },
    clearMapSelected(data){
      delete this.features[data]
    },
    clearMap() {

      this.$refs.map.$map.getLayers().forEach(layer => {
          if (layer instanceof VectorLayer) {
              layer.getSource().clear()
          }
      });

      this.$refs.map.$map.updateSize()
      this.features = []
      this.featuresSelected = []
      this.showCurvesForm = false
      this.importOn = false

    },
    ...mapActions(['saveLineStringFunc','updateStoragedProps','getVector','getLayers','updateAlternativePolygon','changeStatus','updateTrack','updateLimit','getCurrentCalc','updateVector','clearAtHome']),
    async goStep(step){
      await this.changeStatus({calcId: this.$route.params.id, payload: {'status': 'Init','step_name': this.steps[step-1], 'step': step, 'notification': this.status.notification}})
    },
    async clickSaveExit() {

      if(this.showCurvesForm === true){
        await this.saveLineStringFunc({calcId: this.$route.params.id, payload: this.lineStringData})
      }
      this.loading1 = true
      if(this.status.step==4){
        if(this.features.length<2){
          alert(this.selectedLanguage['map_alert_text_4'])
        }
        else{
          var points = []
          this.features.forEach(point => {
            points.push(point.geometry.coordinates)
          })
          var lastPoint = points.shift()
          var tooClose = false
          points.forEach(point => {
            const distance = ((lastPoint[0]-point[0])**2+(lastPoint[1]-point[1])**2)**0.5
            if(!tooClose && distance<500){
              alert(this.selectedLanguage['map_alert_text_5'])
              tooClose = true
            }
            lastPoint = point
          })
          if(!tooClose){
            const geom = pointsListToEWKT((this.projection).replace('EPSG:',''),this.features)
            let payload = {geom: geom}

            const res = await this.updateTrack({calcId: this.$route.params.id,payload: payload})
            if(res==true){
              this.features = []
              await this.getCurrentCalc(this.$route.params.id)
              await this.goStep(this.status.step+1)
            }
            else{
              alert(this.selectedLanguage['map_alert_text_1'])
            }
          }
        }
      } else {
        if(this.status.step==5){
          if(this.features.length<1){
            alert(this.selectedLanguage['map_alert_text_2'])
          }
          else{
            let insidePolygons = this.features
            let outsidePolygon = insidePolygons.shift()
            const geom = polygonsListToEWKT((this.projection).replace('EPSG:',''),outsidePolygon,insidePolygons)
            let payload = {geom: geom}
            const res = await this.updateLimit({calcId: this.$route.params.id,payload: payload})
            if(res==true){
              this.features = []
              await this.goStep(this.status.step+1)
              window.localStorage.removeItem('CurrentSRID')
            }
            else{
              alert(this.selectedLanguage['map_alert_text_3'])
            }
          }
        }
      }
      this.loading1 = false
    },
    async testFeature(){
      this.$emit('itHasFeature', this.features.length)
    },
    callEmitDrawType(){
      this.$emit('clearDrawType', undefined)
    },
    callEmitDrawType2(){
      this.$emit('clearDrawType2', undefined)
    },
    excuteFuncDelete(){
      this.features.pop()
    },
    async updateLimitArea(){
      let insidePolygons = this.features
      let outsidePolygon = {['geometry']: store.getters.alternativePolygon.geojson, ['type']: "Feature"}
      const geom = polygonsListToEWKT((this.projection).replace('EPSG:',''),outsidePolygon,insidePolygons)
      let payload = {geom: geom}
      let alternativeId = store.getters.alternativePolygon.id
      await this.updateAlternativePolygon({calcId: alternativeId ,payload: payload})
      await this.getLayers(store.getters.currentCalc.id)
      await this.changeStatus({calcId: this.$route.params.id, payload: {"status": "HasChanges", "step_name": "Calculate alternatives", "step": 7}})
      this.features = []
    },
    async clickCancelExit() {
      this.loading2 = true
      await this.goStep(this.status.step-1)
      this.loading2 = false
    }
  },
  watch: {
    featuresSelected: {
      handler(val, oldVal) {
        if(this.featuresSelected != [] && this.featuresSelected != undefined && this.drawType != 'delete'){
          this.vectorIdentifyer(this.featuresSelected)
        }
        if (val.length != oldVal.length) {
          if (val.length > oldVal.length) { this.$emit("map", ['select_feature', val])
        }
          else { this.$emit("map", ['unselect_feature', val])
          this.showPropsForm = false
          this.ignoreStatus = false
        }
        }
        // console.log('selected ', this.features)
        // não deu certo a ideia de selecionar a feature, ela entra em loop n sei o pq ainda
        return
      }
    },
    baseLayerIdx: {
      handler(){
        this.localbaseLayerIdx = this.baseLayerIdx
      }
    },
    drawType: {
      handler(){
        if(this.drawType == "Polygon" && this.status.step == 7){
          this.internalDrawType = this.drawType
        }
        else if(this.drawType == 'delete' && this.status.step == 7){
          // this.callEmitDrawType()
          // this.features.pop()
        }
        else if(this.drawType == 'select' && this.status.step == 7){
          // this.callEmitDrawType2()
          // this.features.pop()
        }
      }
    },
    features:{
      handler(){
        this.testFeature()
        if(this.status.step == 4){
          if(this.features.length >= 2){
            this.internalDrawType = undefined
          } else if(this.features.length < 2){
            this.internalDrawType = 'Point'
          }
        }
      }
    },
  }
}
</script>

<style>
#info {
  z-index: 3;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  background: rgba(0,60,136,0.7);
  color: white;
  border: 0;
  transition: opacity 100ms ease-in;
}
.ol-control button {
  display: none;
}
.ol-search ul {
    color: #333;
    font-size:0.85em;
    width: 40vw;
}
.ol-search ul i {
    color: #333;
    font-size:0.85em;
}
.nominatim{
  display: block;
  top: 1vh;
  left: 1vw;
  position: absolute;
  z-index: 4;
}
.search{
  border: #4f758b solid 2px;
}
.autocomplete{
  background-color: white;
  width: 100%;
}
.search{
  width: 40vw;
  height: 30px;
  background-color: white;
  padding-left: 15px;
  border-radius: 6px;
}
.v-menu__content {
    box-shadow: none;
}
@media only screen and (max-width: 576px) {
		#MenuOnTop{
      overflow-x: auto;
    }
	}
</style>